import React from 'react';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';

const Vaccine = () => {
  return (
    <div className="container">
      <Helmet>
        <title> The Shoyaright! </title>
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@zuffshoya" />
        <meta name="twitter:title" content="Shoya, Inc. to Develop Coronavirus Vaccine" />
        <meta name="twitter:image" content="http://www.shoya.co/static/shoyaright_icon-ada66d9717351d5e161cdd4c5d83603b.png" />
        <meta name="twitter:image:alt" content="The Shoyaright! Logo" />
      </Helmet>
      <div className="article">
        <Link to="/shoyaright">
        <div className="shoyarightChomskyArticle" />
        </Link>
        <div className="articleTitle">
          <p> Shoya, Inc. to Develop Coronavirus Vaccine</p>
        </div>
        <div className="byLine">
          <p> By: Lil Shoyaright! (Pub. Apr. 11, 2020) </p>
        </div>
        <div className="articleBody">
        <p>
        The corporation's main pharmaceutical subsidiary, Pill Pills Pills Pharmeceutics dba P3 Pharma (formerly Merck & Co., renamed after its acquisition in an homage to <a href="https://www.youtube.com/watch?v=NiF6-0UTqtc">Destiny's Child</a>) is set to begin clinical trials with its vaccine in the coming weeks, testing primarily on white suburban teenagers.
        </p>
        <p>
        Shoya, Inc. has also provided increased funding to another of its pharmaceutical holdings, Ligma Ltd., in an effort to spur competitive haste. The CEO of Shoya, Inc. "pinky-swears" not to price gouge once a vaccine becomes viable, which he claims will take "bro 3 months max frfr" despite expert projections of 18+ months.
        </p>
        <p>
        ...ard that's about as much satire as I can stomach right now. I lie to y'all not I'm quarantined in the crib w/ two dudes named Malik and a pull-up bar...so yehmean jailhouse physique loading obviously, but the cost is too high God!
        </p>
        <p>
        I know this situation ain't for play but after a month of being quiet about it all, I thought I should start tryna chart a course to some new normal. I hope some of y'all are thinking of doing the same, cuz whenever this passes, and even before then, we got our work cut out.
        </p>
        <p>
        Anyway if any of y'all got my line and feeling blue, feel free to call me I can sing you any track off that new Biebs for some levity.
        </p>

        </div>
        <p className="plug">If you enjoyed this article, consider <a href="https://www.patreon.com/join/shoyaright?">becoming a patron</a> or making a <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=zuff@shoya.co&lc=US&item_name=The%20Shoyaright%21&currency_code=USD&no_note=0&bn=PP-DonationsBF:btn_donateCC_LG.gif:NonHostedGuest">one-time pledge</a>. Support corporate journalism!</p>
        <div className="articleCopyright">
          <p>&copy; Shoya, Inc. 2020</p>
        </div>
      </div>
    </div>

  );
};
export default Vaccine;
